var UtilitiesService = /** @class */ (function () {
    function UtilitiesService() {
    }
    UtilitiesService.prototype.moveElementToFirstPosition = function (collection, targetCriteria) {
        var foundElement = collection.find(targetCriteria);
        if (foundElement) {
            collection = [
                foundElement
            ].concat(collection.filter(function (contact) { return !targetCriteria(contact); }));
        }
        return collection;
    };
    return UtilitiesService;
}());
export { UtilitiesService };
