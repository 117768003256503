import { Injectable } from '@angular/core';

@Injectable()
export class UtilitiesService {
  constructor() {}
  
  moveElementToFirstPosition(collection: any[], targetCriteria: (contact: any) => any): any[] {    
    const foundElement = collection.find(targetCriteria);
    if (foundElement) {
      collection = [
        foundElement,
        ...collection.filter(
          (contact) => !targetCriteria(contact)
        ),
      ];
    }
    return collection;
  }
}
