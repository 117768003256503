<router-outlet></router-outlet>

<div class="container" *ngIf="verbiage">
  <div class="row row1">
    <div class="col-5 login-col">
      <div class="row">
        <div class="left-container">
          <div class="logo-img">
            <img src="assets/images/Qwikkit-Logo-noTag-Green-White-RGB.png" width="327" alt="logo" class="logo">
          </div>
          <div class="qwikkit-headline">
            <span>{{ verbiage.loginPageSubtitleText }}</span>
          </div>
          <div class="login-form">
            <div class="loginField">
              <h3 class="sub-title-h3 subTitle">{{LoginTextValues.loginTxt}}</h3>
              <form [formGroup]="loginForm" (ngSubmit)="signIn()">
                <div class="no-lateral-padding">
                  <input [formControl]="loginForm.controls['username']" name="username" (keyup)="cleanLogin($event)"
                    id="username" [(ngModel)]="credentials['username']" type="email" placeholder="User Name" required>
                  <div
                    *ngIf="loginForm.controls['username'].hasError('required') && loginForm.controls['username'].touched || emptyUser"
                    class="emptyField alert alert-danger">You must add an email.</div>
                </div>
                <div class="no-lateral-padding">
                  <input [formControl]="loginForm.controls['password']" name="password" (keyup)="cleanLogin($event)"
                    id="password" [(ngModel)]="credentials['password']" type="password" required placeholder="Password">
                  <div
                    *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched || emptyPass"
                    class="emptyField alert alert-danger">{{loginError}}</div>
                </div>
                <div class="no-lateral-padding">
                  <a (click)="modalPass()" class="forgotPass">{{LoginTextValues.forgotPass}}</a>
                </div>
                <div class="no-lateral-padding">
                  <div class="row">
                    <div class="col-12">
                      <button *ngIf="buttonStatus" type="submit" class="base-button buttons">{{LoginTextValues.sign}}</button>
                    </div>
                    <div class="col-12">
                      <p class="web-leyend"> This site works with Chrome, Microsoft Edge, Firefox and Safari. It
                        does not support Internet Explorer.</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-7 bground-image"></div>
  </div>
  <div class="row card-container">
    <div class="col-md-4 col-sm-12">
      <div class="card">
        <h4 class="card-title">{{ verbiage.loginPageLeftCardTitleText }}</h4>
        <p class="card-text">{{ verbiage.loginPageLeftCardContentText }}</p>
        <button (click)="openNewTabNewAccount()" class="base-button card-button">{{ verbiage.loginPageLeftCardButtonText }}</button>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="card">
        <h4 class="card-title">{{ verbiage.loginPageMiddleCardTitleText }}</h4>
        <p class="card-text">{{ verbiage.loginPageMiddleCardContentText }}</p>
        <button class="base-button card-button" (click)="openTabHomeOwner()">{{ verbiage.loginPageMiddleCardButtonText }}</button>
      </div>
    </div>
    <div class="col-md-4 col-sm-12" *ngIf="verbiage.loginPageShowAnnouncement === 'true'">
      <div class="card">
        <h4 class="card-title">{{ verbiage.loginPageRightCardTitleText }}</h4>
        <p class="card-text">{{ verbiage.loginPageRightCardContentText }}</p>
        <button *ngIf="verbiage.loginPageRightCardButtonVisible === 'true'"(click)="goToURL(verbiage.loginPageRightCardButtonURL)" class="base-button card-button">{{ verbiage.loginPageRightCardButtonText }}</button>
      </div>
    </div>
  </div>
</div>

<app-modal #forgotPass (closed)="resetView($event)">
  <div class="modal-body">
    <h2 *ngIf="!email_sent" class="h2-styling note-title">Reset My<br>Password</h2>
    <input required type="email" class="email" placeholder="Email"
      pattern="(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,20}\.[0-9]{1,20}\.[0-9]{1,20}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,14}|[0-9]{1,20})(\]?)(\s*;\s*|\s*$))*"
      *ngIf="!email_sent" [(ngModel)]="userEmail" [formControl]="sendEmail"><br>
    <app-spinner #spinner></app-spinner>
    <button *ngIf="!email_sent" [class.inactive]="sendEmail.invalid" style="margin-right: 1rem;" class="buttons"
      (click)="sendMail(userEmail)">Submit</button>
    <span *ngIf="sendEmail.invalid && userEmail.length < 1" class="error" class="error">{{emailRequired}}</span>
    <span *ngIf="sendEmail.dirty && sendEmail.invalid && userEmail.length > 0" class="error"
      class="error">{{emailError}}</span> <br> <br> <br>
    <a *ngIf="!email_sent" (click)="forgotPass.hideChildModal()">Nevermind, I remember.</a>
    <div class="email-sent" *ngIf="email_sent">
      <div class="check">
        <i class="material-icons">{{materialIconName}}</i>
      </div>
      <span class="sent">{{textMessage}}</span>
    </div>
  </div>
</app-modal>

<app-modal #multipleAccounts [isHeighter]="'auto'">
  <div class="modal-body">
    <h2 class="h2-styling note-title">Which account do you want?</h2>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 align-content-center">
          <div class="search-container">
            <input type="text" class="form-control search-input" placeholder="Search account"
              [(ngModel)]="searchAccount" (keyup)="onSearchAccounts(searchAccount)">
            <i class="icon material-icons">&#xE8B6;</i>
          </div>
        </div>
      </div>
      <div class="row mt-1 justify-content-center">
        <div class="col-12 table-responsive" style="height: 20vh;">
          <table class="table table-borderless table-sm">
            <tbody>
              <tr *ngFor="let account of accounts">
                <!-- <td *ngIf="account.blocked ==' '" ><a (click)="setCustomerID(account.customerID)" href="javascript:void(0)">{{ account.customerNumber }}</a></td>
                <td *ngIf="account.blocked !=' '" >{{ account.customerNumber }}</td> -->
                <td><a (click)="setCustomerID(account.customerID)" href="javascript:void(0)">{{ account.customerNumber }}</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #customerLedgerModal>
  <div class="modal-body">
    <app-ledge-modal [title]="messageLedge" [jsonLedge]="customerLedge" [total]="total"
      (tableLedge)="tableLedge($event)">
    </app-ledge-modal>
    <button class="buttonOk" (click)="customerLedgerModal.hideChildModal()">Ok</button>
  </div>
</app-modal>